import { CmsGuideLayout } from "../../../components/CmsGuideLayout"
import { lorem } from "../../../lib/lorem"
import { PageDataBodyCountdown } from '../../../slices/PageDataBodyCountdown'

const Component = () => {
  return (
    <CmsGuideLayout>
      <PageDataBodyCountdown
        heading={lorem.sentences(1)}
        eventDate={new Date().setDate(new Date().getDate() + 123)}
      />
    </CmsGuideLayout>
  )
}

export default Component